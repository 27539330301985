.step{
    padding: 50px 0;
    background: #fff;
    font-family: 'Barlow' ,sans-serif;
}

.step1{
    background: #fff5f7;
}

.step1 h4{
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.step1 .info{
    font-size: 14pt;
    margin: 0;
}

.step2{
    background: #ebf9ff;
}

.step2 h4{
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.step2 .info{
    font-size: 14pt;
    margin: 0;
}