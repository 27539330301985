@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #f3f3f3;
}


.orderBlock{
    font-family: 'Barlow' ,sans-serif;
}

.card-header{
    background: #222;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Barlow' ,sans-serif;
}

.card-header h2{
    margin: 0;
}

.orderBlock .card-body{
    background: #d0d9da;
}

.btn-success{
    width: 100%;
    background: #64e62e;
    border-color: #64e62e;
    min-height: 70px;
    font-size: 1.5rem;
    font-weight: 600;
    border: 4px solid #64e62e;
    transition: 0.4s all ease-in-out;
}

.form-control{
    height: calc(2em + .75rem + 2px);
}

.btn-success:hover{
    background: #cc0000;
    border-color: #64e62e;
}

.description{
    font-size: 17px;
    color: #555;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;;
}

.description span{
    display: block;
    color: red;
    font-weight: 600;
}
.intro{
    background: #f3f3f3;
    border-bottom: 5px solid #64e62e;
    padding: 50px 0;
}

.intro-text{
    color: #376f25;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 18pt;
    font-weight: 600;
    margin-bottom: 2rem;
}

.span{
    background-color: #376f25;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
}

.dugme{
    display: none;
}

@media (max-width: 768px){
    .dugme{
        display: block;
    }
}


.step{
    padding: 50px 0;
    background: #fff;
    font-family: 'Barlow' ,sans-serif;
}

.step1{
    background: #fff5f7;
}

.step1 h4{
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.step1 .info{
    font-size: 14pt;
    margin: 0;
}

.step2{
    background: #ebf9ff;
}

.step2 h4{
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.step2 .info{
    font-size: 14pt;
    margin: 0;
}
.final{
    background: #f3f3f3;
    padding: 50px 0;
}

.intro-text{
    color: #376f25;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 18pt;
    font-weight: 600;
    margin-bottom: 2rem;
}

.span{
    background-color: #376f25;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
}


