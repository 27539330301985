.intro{
    background: #f3f3f3;
    border-bottom: 5px solid #64e62e;
    padding: 50px 0;
}

.intro-text{
    color: #376f25;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 18pt;
    font-weight: 600;
    margin-bottom: 2rem;
}

.span{
    background-color: #376f25;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
}

.dugme{
    display: none;
}

@media (max-width: 768px){
    .dugme{
        display: block;
    }
}

